import React from 'react';
import './Navbar.css';
import { IonIcon } from '@ionic/react';
import { personOutline,menuOutline } from 'ionicons/icons';

const Navbar = () => {
  return (
    <div className="navbar">
        <IonIcon className="icon" icon={personOutline} />
    <div className='logoo'>
    <span className="logo">ink</span>
    {/* <span className="url">estiasi.inkblue.net</span> */}
    </div>
      <a href="#">
      <div class="iconbar">
        <div class="bar bar1"></div>
        <div class="bar bar2"></div>
        <div class="bar bar3"></div>
    </div>
      </a>
    </div>
  );
};

export default Navbar;
