import React from 'react';
import './App.css';
import { Calendar, Card, Navbar, BottomMenu } from './assets/components';
import { useMediaQuery } from 'react-responsive';

const App = () => {
  // Define a media query for large devices
  const isLargeDevice = useMediaQuery({ query: '(min-width: 768px)' });

  return (
    <div>
      {isLargeDevice ? (
        <div className="large-device-message">
          Please open this app on small devices.
        </div>
      ) : (
        <div className="app-content">
          <Navbar />
          <Calendar />
          <Card />
          <BottomMenu />
        </div>
      )}
    </div>
  );
};

export default App;
