import React, { useState, useEffect } from 'react';
import { format, startOfMonth, endOfMonth, eachDayOfInterval, addMonths, subMonths, getDaysInMonth } from 'date-fns';
import './Calendar.css';
import { IonIcon } from '@ionic/react';
import { calendarClearOutline } from 'ionicons/icons';

const months = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
];

const daysInMonth = (month, year) => getDaysInMonth(new Date(year, month));

const InputSection = ({ selectedDate, values, handleInputChange, handleSave }) => {
  const isSaveDisabled = !values['Cash'] && !selectedDate;

  return (
    <div className="inputs-section">
      <h4>Enter values for {selectedDate ? selectedDate.toDateString() : 'a date'}</h4>
      {['Cash', 'Credit Card', 'Other', 'VAT'].map((label, index) => (
        <div className="input-group" key={index}>
          <label>{label === 'Cash' ? 'Cash *' : label}</label>
          <input
            type="number"
            placeholder='€'
            value={values[label] || ''}
            onChange={e => handleInputChange(label, e.target.value)}
          />
        </div>
      ))}
      <div className='input-group'>* Required fields</div>
      <button className='save' onClick={() => handleSave()} disabled={isSaveDisabled}>Save</button>
      <button className='dailybtn'>Back to Daily Income</button>
      <button className='previous'>Income of previous years</button>
    </div>
  );
};

const Calendar = () => {
  const [currentView, setCurrentView] = useState('months'); // 'months', 'dates', 'calendar'
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [displayedMonth, setDisplayedMonth] = useState(new Date().getMonth());
  const [displayedYear, setDisplayedYear] = useState(new Date().getFullYear());
  const [monthTotals, setMonthTotals] = useState({});
  const [dateValues, setDateValues] = useState({});
  const [currentValues, setCurrentValues] = useState({});
  const [expandedDate, setExpandedDate] = useState(null);
  const [dailyTotals, setDailyTotals] = useState({});
  const [selectedDailyTotal, setSelectedDailyTotal] = useState(0);

  useEffect(() => {
    const savedDateValues = JSON.parse(localStorage.getItem('dateValues')) || {};
    setDateValues(savedDateValues);

    calculateTotals(savedDateValues);
  }, []);

  const calculateTotals = (dateValues) => {
    const totals = {};
    const dailyTotals = {};

    for (let date in dateValues) {
      const [year, month, day] = date.split('-');
      const monthKey = `${year}-${month}`;
      if (!totals[monthKey]) totals[monthKey] = 0;
      totals[monthKey] += Object.values(dateValues[date]).reduce((acc, val) => acc + parseFloat(val || 0), 0);

      const dateKey = format(new Date(date), 'yyyy-MM-dd');
      dailyTotals[dateKey] = Object.values(dateValues[date]).reduce((acc, val) => acc + parseFloat(val || 0), 0);
    }

    setMonthTotals(totals);
    setDailyTotals(dailyTotals);
  };

  const handleMonthClick = (monthIndex) => {
    const monthKey = `2024-${String(monthIndex + 1).padStart(2, '0')}`;
    if (monthTotals[monthKey]) {
      setSelectedMonth(monthKey);
      setCurrentView('dates');
    }
  };

  const handleDateClick = (date) => {
    setSelectedDate(date);
    const dateKey = format(date, 'yyyy-MM-dd');
    setSelectedDailyTotal(dailyTotals[dateKey] || 0);
    setCurrentValues(dateValues[dateKey] || {});
  };

  const handleInputChange = (label, value) => {
    setCurrentValues(prevValues => ({
      ...prevValues,
      [label]: value
    }));
  };

  const handleSave = () => {
    if (!selectedDate) {
      console.error('No date selected');
      return;
    }
    const dateKey = format(selectedDate, 'yyyy-MM-dd');
    const updatedDateValues = {
      ...dateValues,
      [dateKey]: currentValues
    };
    setDateValues(updatedDateValues);
    localStorage.setItem('dateValues', JSON.stringify(updatedDateValues));

    calculateTotals(updatedDateValues);

    setSelectedDate(null);
    setSelectedMonth(null);
    setCurrentView('months');
  };

  const handleExpandDate = (date) => {
    setExpandedDate(expandedDate === date ? null : date);
  };

  const handleAddButtonClick = () => {
    setCurrentView('calendar');
  };

  const handlePrevMonthClick = () => {
    setDisplayedMonth(prev => {
      const newDate = subMonths(new Date(displayedYear, prev, 1), 1);
      setDisplayedYear(newDate.getFullYear());
      return newDate.getMonth();
    });
  };

  const handleNextMonthClick = () => {
    setDisplayedMonth(prev => {
      const newDate = addMonths(new Date(displayedYear, prev, 1), 1);
      setDisplayedYear(newDate.getFullYear());
      return newDate.getMonth();
    });
  };

  const formatValue = (value) => {
    return parseFloat(value).toLocaleString('en', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
    });
  };

  const monthKey = `${displayedYear}-${String(displayedMonth + 1).padStart(2, '0')}`;
  const dates = selectedMonth ? eachDayOfInterval({
    start: startOfMonth(new Date(selectedMonth)),
    end: endOfMonth(new Date(selectedMonth))
  }).filter(date => dateValues[format(date, 'yyyy-MM-dd')]) : [];

  return (
    <div className="app-container">
      <div>
        <h2>07.06.2024</h2>
        <h3>DAILY INCOME 2024</h3>
      </div>
      {currentView !== 'calendar' && (
        <div className="add-button" onClick={handleAddButtonClick}>+</div>
      )}
      <p className='turnover'>Add the turnover of the day</p>
      <button className="daily-income">Daily Income</button>
      {currentView === 'months' && (
        <div>
          <div className="calendar">
            {months.map((month, index) => {
              const monthKey = `2024-${String(index + 1).padStart(2, '0')}`;
              const hasValues = monthTotals[monthKey];
              return (
                <div
                  key={index}
                  className={`month ${hasValues ? 'clickable' : 'disabled'}`}
                  onClick={() => hasValues && handleMonthClick(index)}
                >
                  {month}
                  <div className="month-total">
                    {hasValues ? `${formatValue(monthTotals[monthKey])} €` : ''}
                  </div>
                </div>
              );
            })}
          </div>
          <div>
            <p className='total-txt'>Total: {formatValue(Object.values(monthTotals).reduce((acc, val) => acc + val, 0))} €</p>
            <button className='previous'>Income from previous years</button>
          </div>
        </div>
      )}
      {currentView === 'dates' && selectedMonth && (
        <div className="month-details">
          <div>
            <h3 className='monthname'><IonIcon className='calendaricon' icon={calendarClearOutline} /> {months[parseInt(selectedMonth.split('-')[1], 10) - 1]} 2024</h3>
            {dates.map(date => {
              const dateKey = format(date, 'yyyy-MM-dd');
              return (
                <div key={dateKey} className="date-item" onClick={() => handleExpandDate(dateKey)}>
                  <span>{date.getDate()} {months[date.getMonth()].slice(0, 3)}</span>
                  <span>{dateValues[dateKey] ? `${formatValue(Object.values(dateValues[dateKey]).reduce((acc, val) => acc + parseFloat(val || 0), 0))} €` : ''}</span>
                  {expandedDate === dateKey && dateValues[dateKey] && (
                    <div className="date-details">
                      {Object.entries(dateValues[dateKey]).map(([label, value]) => (
                        <div key={label}>
                          <span>{label}</span>
                          <span>{formatValue(value)} €</span>
                        </div>
                      ))}
                      <div className="total">Total: {formatValue(Object.values(dateValues[dateKey]).reduce((acc, val) => acc + parseFloat(val || 0), 0))} €</div>
                    </div>
                  )}
                </div>
              );
            })}
            {/* Selected month total */}
            <div className="month-totals total-txt">
              <strong>Total for {months[parseInt(selectedMonth.split('-')[1], 10) - 1]}:</strong> {formatValue(monthTotals[selectedMonth] || 0)} €
            </div>
          </div>
          <div className='analysis'>
            <p className='astrik'>*</p>
            <p className='atext'>Click on the date to see the income analysis of the day</p>
            <button className='dailybtn' onClick={() => setCurrentView('months')}>Back to Daily Income</button>
            <button className='previous'>Income of previous years</button>
          </div>
        </div>
      )}

      {currentView === 'calendar' && (
        <div>
          <div className="calendar-container">
            <div className="month-navigation">
              <button onClick={handlePrevMonthClick}>&lt;</button>
              <span>{months[displayedMonth]} {displayedYear}</span>
              <button onClick={handleNextMonthClick}>&gt;</button>
            </div>
            <div className="calendar-header">
              {['S', 'M', 'T', 'W', 'T', 'F', 'S'].map((day, index) => (
                <span key={index}>{day}</span>
              ))}
            </div>
            <div className="calendar-grid">
              {Array.from({ length: startOfMonth(new Date(displayedYear, displayedMonth)).getDay() }).map((_, i) => (
                <div key={`empty-${i}`} className="day empty"></div>
              ))}
              {Array.from({ length: daysInMonth(displayedMonth, displayedYear) }, (_, i) => (
                <div
                  key={i}
                  className="day"
                  onClick={() => handleDateClick(new Date(displayedYear, displayedMonth, i + 1))}
                >
                  {i + 1}
                </div>
              ))}
            </div>
          </div>
          <div className="monthly-total">
            Monthly Total: {formatValue(monthTotals[monthKey] || 0)} €
          </div>
          <InputSection
            selectedDate={selectedDate}
            values={currentValues}
            handleInputChange={handleInputChange}
            handleSave={handleSave}
          />
          {selectedDate && (
            <div className="daily-total-display">
              Daily Total: {formatValue(selectedDailyTotal)} €
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Calendar;
