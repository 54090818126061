import React from 'react';
import './card.css';
import { IonIcon } from '@ionic/react';
import { alertCircleOutline } from 'ionicons/icons';

const Card = () => {
  return (
    <div  id='appCapsule'>
      <div className='container full mt-2'>
      <div className="card text-center">
        <div className="card-body">
        <IonIcon icon={alertCircleOutline} />
          <h5 className="card-title">Attention!</h5>
          <p className="card-text">If you experience difficulties, see the Help Video:</p>
          <p className="card-text">• How to add the daily income</p>
          <a href="#" className="btn">See the Video</a>
          <p className="card-text fontIt">Not to appear again this notification</p>
        </div>
      </div>
   </div>
   </div>

  
  );
};


export default Card;
