import React from 'react';
import './BottomMenu.css';

// Import images
import settingsIcon from '../../icons/settings.png';
import supportIcon from '../../icons/support.png';
import explainVideosIcon from '../../icons/play video.png';
import calculatorIcon from '../../icons/calculator.png';

const MenuItem = ({ href, icon, altText, label }) => (
  <a href={href} className="item">
    <div className="col">
      <img src={icon} alt={altText} className="menu-icon" />
      <strong>{label}</strong>
    </div>
  </a>
);

const BottomMenu = () => (
  <div id="appCapsule">
    <div className="appBottomMenu">
      <MenuItem href="#" icon={settingsIcon} altText="Settings" label="Settings" />
      <MenuItem href="#" icon={supportIcon} altText="Support" label="Support" />
      <MenuItem href="#" icon={explainVideosIcon} altText="Explain Videos" label="Explain Videos" />
      <MenuItem href="#" icon={calculatorIcon} altText="Calculator" label="Calculator" />
    </div>
  </div>
);

export default BottomMenu;
